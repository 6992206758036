import React from 'react'
import Maintenace from '../../components/maintenace'

const TradePage = () => {
    return(
            <div className="page">
                <Maintenace />
            </div>
    )
}

export default TradePage