import React from 'react';

const AboutSection = () => {


    return(
        <div>
            About
        </div>
    )
}

export default AboutSection