import React from 'react'


const Maintenace = () => {

    return(
        <div className="scheme--section">
            <div className="maintenace--container">
                Page now in research
            </div>
        </div>

    )
}

export default Maintenace